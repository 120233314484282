import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import URLOpener from '../components/urlOpener';

const CustomLinkPage = ({
  data,
}: {
  data: {
    linksJson: {
      description: string;
      title: string;
      url: string;
      image: string;
    };
  };
}) => {
  const { title, description, url, image } = data.linksJson;
  return (
    <Layout>
      <SEO title={title} description={description} image={image} />
      <URLOpener path={url} />
    </Layout>
  );
};

export const query = graphql`
  query getLink($url: String!) {
    linksJson(url: { eq: $url }) {
      title
      description
      url
      image
    }
  }
`;

export default CustomLinkPage;
