import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { APP_PREFIX, APPLE_STORE_LINK, GOOGLE_PLAY_LINK } from '../config';
import styled from '@emotion/styled';

const StyledLink = styled.a`
  background: #ff8934;
  padding: 20px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  margin-top: 40px;
  display: block;

  &:hover {
    background: #e87b2d;
  }
`;

const URLOpener = ({ path }: { path: string }) => {
  const appPath = `${APP_PREFIX}${path}`;
  useEffect(() => {
    setTimeout(function () {
      const url = isIOS ? APPLE_STORE_LINK : isAndroid ? GOOGLE_PLAY_LINK : null;
      if (url) {
        window.location.replace(url);
      }
    }, 25);
  }, []);

  return (
    <div>
      <StyledLink href={appPath}>Open application</StyledLink>
    </div>
  );
};

export default URLOpener;
